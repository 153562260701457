<template>
    <div class="container">
        <el-input class="commonSearch" placeholder="根据用户ID、手机号、邮箱搜索" v-model="searchKey" clearable>
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
        
        <div class="commonList">
            <table v-show="userList.length>0" v-loading="isLoading">
                <thead>
                    <tr>
                        <td>ID</td>
                        <td>用户名</td>
                        <td>邮箱</td>
                        <td>手机</td>
                        <td>性别</td>
                        <td>微博</td>
                        <td>微信</td>
                        <td>注册时间</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,index) in userList" :key="index">
                        <td>{{item.id}}</td>
                        <td><a :href="'https://www.sidoc.cn/user/' + item.id" target="_blank">{{item.user_nickname}}</a></td>
                        <td>{{item.user_email}}</td>
                        <td>{{item.user_phone}}</td>
                        <td>
                            <!-- 男:1,女:2,保密:0' -->
                            <span v-if="item.user_sex == 1">男</span>
                            <span v-if="item.user_sex == 2">女</span>
                            <span v-else>保密</span>
                        </td>
                        <td>{{item.user_weibo}}</td>
                        <td>{{item.user_weixin}}</td>
                        <td>{{item.create_time}}</td>
                    </tr>
                </tbody>

            </table>

            <el-pagination
               background
               @current-change="loadData"
               :current-page.sync="currentPage"
               :page-size="pageSize"
               layout="total, prev, pager, next"
               :total="total"
               style="text-align: right;margin-top: 10px;">
            </el-pagination>
        </div>

    </div>
</template>

<script>

export default {

    data(){
        return {
            userList:[],
            isLoading:false,

            searchKey:"",
            currentPage:0,
            pageSize:18,
            total:0
        };
    },
    mounted(){
        this.loadData(this.currentPage);
    },
    methods:{
        loadData(currentPage){
            let that = this;
            this.isLoading = true;
            let params = {
               currentPage:currentPage,
               pageSize:this.pageSize,
               searchKey:this.searchKey
            }
            this.$api.request({
                url: process.env.VUE_APP_BASE_URL_USER + '/userList',
                data:params,
                method: 'post',
            }).then(res => {
                if(res.code == 0){
                    that.userList = res.data.list;
                    that.total    = res.data.count;
                }
            }).finally(()=>{
               that.isLoading = false;
            });
        }
    },
    watch:{
        searchKey(){
            this.loadData(1);
        }
    }
    
}
</script>

